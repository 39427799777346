<template>
  <div>
     <el-container>
        <el-aside :width="width">
          <subMenu :isShow="isCollapse" @func="getMenus"/>
        </el-aside>
        <div :style="{'margin-left':width}">
              <div class="topheader">
                <div class="searchInput">
                  <span v-if="menuName">当前位置：{{menuName.name}} </span>
                </div>
                <passWord @adminrole="getAdmins"/>
              </div>
            <el-main>
              <router-view />
            </el-main>
        </div>
      </el-container>
  </div>
</template>

<script>
import subMenu from "@/components/Navmenu"
import passWord from "@/components/password"
export default {
  name: "dashboard",
  components: { subMenu, passWord },
  data() {
    return {
      isCollapse: '',
      width: "160px",
      menuName: '',
    };
  },
  methods: {
    getMenus(val){
      this.menuName = val
      console.log('我选择的' + JSON.stringify(val));
    },
    getAdmins(val){
      console.log(JSON.stringify(val))
      console.log('返回的角色类型')
      this.isCollapse = val
      var curpath = this.$route.path
      console.log(curpath)
      if(curpath.indexOf('/dashboard')>=0){
        if(val =='wms'){
          this.$router.push({
            path: "/wms/dashboard"
          }, ()=>{},()=>{})
        }else{
          this.$router.push({
            path: "/dashboard"
          }, ()=>{},()=>{})
        }
      }
    }
  },
  watch:{
    isCollapse: function(newval, oldval){
      console.log(newval,oldval)
    }
  }
}
</script>