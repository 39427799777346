<template>
  <div class="subbg">
    <div class='topmenu'></div>
    <el-menu :default-active='curIndex' mode='vertical' class='el-menu-vertical-demo'
    :collapse="isCollapse" text-color="#fff" @select='selectFun' @open='handleOpen' @close='handleClose' unique-opened>
      <template>
        <label v-for='menus in menuArrs' :key="menus.id">
       
          <el-submenu v-if="menus.children && menus.children.length>0" :index="menus.id">
            <template slot='title'>
              <i class="iconfont" :class='menus.icon?menus.icon:"icon-xiangmu"'></i>
              <span>{{menus.name}}</span>
            </template>
            <label v-for='subs in menus.children' :key="subs.id">
              <router-link :to="subs.router" class='noLine'>
                <el-menu-item :index="menus.id + '-' + subs.id">{{subs.name}}</el-menu-item>
              </router-link>
            </label>
          </el-submenu>
        
          <router-link v-else :to="menus.router" class='noLine'>
            <el-menu-item :index="menus.id">
               <i class="iconfont" :class='menus.icon?menus.icon:"icon-xiangmu"'></i>
              <span slot='title'>{{menus.name}}</span>
            </el-menu-item>
          </router-link>
        </label>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { chanageMenu } from '@/api/common'
export default {
  name: 'subMenu',
  props: ['isShow'],
  data () {
    return {
      curIndex: '1419905333057331201',
      isCollapse: false,
      role: window.sessionStorage.getItem('role'),
      menuArr: [
        {
          id: '1419905333057331201',
          name: '开团大厅',
          router: '/dashboard',
          icon: 'iconfont icon-home'
        },
        {
          id: '1417447654326046721',
          name: '我分销的团',
          router: '/mytuan',
          icon: 'iconfont icon-gouwu',
        },
        {
          id: '1417447491037597698',
          name: '我发布的团',
          router: '/sendtuan',
          icon: 'iconfont icon-gouwu',
        },
        {
          id: '1417448029644951554',
          name: '我的供应商',
          router: '/supplier',
          icon: 'iconfont icon-wode2'
        },
        {
          id: '1417448196179791873',
          name: '我的分销商',
          router: '/retailer',
          icon: 'iconfont icon-fenxiao'
        },
        {
          id: '1417448458114076673',
          name: '商品管理',
          router: '/goods',
          icon: 'iconfont icon-xiangmu',
        },
        {
          id: '1441407441165119489',
          name: '商品管理',
          icon: 'iconfont icon-xiangmu',
          children: [
            { id: '1', name: '基础商品', router: '/retailergoods', icon: 'element iconmendian' },
            { id: '1441376279893929986', name: '渠道商品', router: '/platformsku', icon: 'element iconmendian' },
          ]
        },
        {
          id: '1417448729670094850',
          name: '订单管理',
          router: '/dingdan',
          icon: 'iconfont icon-dingdan3'
        },
        {
          id: '1417448868400893953',
          name: '订单管理',
          router: '/supdingdan',
          icon: 'iconfont icon-dingdan3'
        },
        {
          id: '1447107001363484673',
          name: '机器人',
          icon: 'iconfont icon-jiqiren',
          children: [
            { id: '1447107401453948929', name: '供应商群管理', router: '/suppliergroup', icon: 'element iconmendian' },
            { id: '1422824800572698625', name: '机器人设置', router: '/reboter', icon: 'element iconmendian' },
            { id: '1447107174890229762', name: '机器人消息', router: '/weixinMsg', icon: 'element iconmendian' },
          ]
        },
        {
          id: '1422824800572698625',
          name: '机器人',
          icon: 'iconfont icon-jiqiren',
          children: [
            { id: '1422825124016451586', name: '渠道群管理', router: '/channel', icon: 'element iconmendian' },
            { id: '1447107001363484673', name: '机器人设置', router: '/reboter', icon: 'element iconmendian' },
            { id: '1422824957712297986', name: '机器人消息', router: '/weixinMsg', icon: 'element iconmendian' },
          ]
        },
       
        {
          id: '1417449157778509826',
          name: '模板管理',
          icon: 'iconfont icon-moban',
          children: [
            { id: '1417449157778509826', name: '发货模板', router: '/sendmoban', icon: 'element iconmendian' },
            { id: '1417449157778509827', name: '运费模板', router: '/freight', icon: 'element iconmendian' },
          ]
        },
        {
          id: '1417449346492829698',
          name: '模板管理',
          icon: 'iconfont icon-moban',
          children: [
            { id: '1417449346492829698', name: '导出模板', router: '/sendmoban', icon: 'element iconmendian' },
            { id: '1417449346492829699', name: '运费模板', router: '/freight', icon: 'element iconmendian' },
          ]
        },
        {
          id: '1513386019309793282',
          name: '数据分析',
          icon: 'iconfont icon-xiaoshoubaobiao',
          router: '/dataanalyse',
        },
        {
          id: '1457596237174317057',
          name: '数据分析',
          icon: 'iconfont icon-xiaoshoubaobiao',
          router: '/dataanalyse',
        },
        {
          id: '1417450031816937474',
          name: '授权渠道',
          router: '/shoplist',
          icon: 'iconfont icon-dianpu'
        },
        {
          id: '1419905494353485825',
          name: '账号管理',
          router: '/team',
          icon: 'iconfont icon-wode'
        },
        {
          id: '1433362080722882561',
          name: '系统设置',
          router: '/system',
          icon: 'iconfont icon-shezhi'
        },
        {
          id: '1433362663848579074',
          name: '系统设置',
          router: '/system/supplier/detail',
          icon: 'iconfont icon-shezhi'
        },
        {
          id: '1487360172941680642',
          name: '财务中心',
          router: '/payorder/financial',
          icon: 'iconfont icon-dingdan3',
          children: [
            { id: '1487360600123154434', name: '资金中心', router: '/payorder/money', icon: 'element iconmendian' },
            { id: '1450807407889354753', name: '账单管理', router: '/payorder/retailer', icon: 'element iconmendian' },
          ]
        },
        {
          id: '1451010071931682817',
          name: '财务中心',
          router: '/payorder/financial',
          icon: 'iconfont icon-dingdan3',
          children: [
            { id: '1451010340086120450', name: '资金中心', router: '/payorder/money', icon: 'element iconmendian' },
            { id: '1450828744049664002', name: '账单管理', router: '/payorder/index', icon: 'element iconmendian' },
          ]
        },
         
      ],
      menuArrs: [],
      resultArr: []
    }
  },
  mounted(){
    console.log(this.isShow)
   // this.chanageMenues()
    console.log('当前路由' + this.$route.path)
  },
  methods: {
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    selectFun (key, keyPath, slot) {
      const that = this
      console.log(key)
      that.curIndex = keyPath[0]
      console.log(that.curIndex)
      that.resultArr.map((item)=>{
        if(item.id == key.split('-')[0]){
          that.sendMsgToParent(item)
        }

      })
    },
    sendMsgToParent (keyname) {
      this.$store.dispatch('changeDispatch', keyname);
      this.$emit('func', keyname)
    },
    chanageMenues () {
      var that = this
      that.menuArrs = []
      var datas = {
        role: window.sessionStorage.getItem('role')
      }
      chanageMenu(datas).then(res => {
          console.log(res)
          if (parseInt(res.code) === 200) {
            that.resultArr = res.data.menu || []
            var curRouter = this.$route.path
            var roles = window.sessionStorage.getItem('role')
            if(roles=='wms'){
              that.curIndex = that.resultArr[0].id
              for (let i = 0; i < that.resultArr.length; i++) {
                var objs = {
                  id: that.resultArr[i].id, 
                  name: that.resultArr[i].name, 
                  router: that.resultArr[i].router,
                  icon: that.resultArr[i].icon
                }
                that.menuArrs.push(objs)
              }
              that.resultArr.map((items, index)=>{
                items.children.map((ites)=>{
                  if(ites.router == curRouter){
                    this.$store.dispatch('changeDispatch', that.resultArr[index]);
                    that.curIndex = that.resultArr[index].id
                  }
                })
              })

            }else{
              for (let i = 0; i < that.resultArr.length; i++) {
                for (let j = 0; j < that.menuArr.length; j++) {
                  if (that.resultArr[i].id === that.menuArr[j].id) {
                    that.menuArrs.push(that.menuArr[j])
                    
                  }
                }
              }
              // that.curIndex = '1419905333057331201'
              that.menuArrs.map((items, index)=>{
                if(items.router == curRouter){
                  that.curIndex = that.menuArrs[index].id
                }
              })
              
           }
           // that.menuArrs = that.menuArr
           // console.log(that.menuArrs)
            // console.log(that.curIndex)
            // console.log(that.$route.path)
          return
            var curUrl = that.$route.path
            that.menuArr.map((item, key) => {
              // console.log('dangUrl99' + item.url)
              if (curUrl.indexOf(item.url) !== -1) {
                var indexs = key
                that.curIndex = that.menuArr[indexs].id
                // console.log(that.curIndex + '2222-----')
                if (item.children && item.children.length >0) {
                  item.children.map((items, keys) => {
                    if (curUrl === items.url) {
                      var indexss = keys
                      that.curIndex = that.menuArr[indexs].id + '-' + that.menuArr[indexs].children[indexss].id
                    }
                  })
                } else {
                  console.log(item.id)
                }
              } else {
                // console.log('dangUrl88' + curUrl)
              }
            })
            // resultArr.map((items, keys) => {
            //   that.menuArrs.push(items.PrivilegeName)
            // })
          }
      })
    }
  },
  watch: {
    isShow: function (newVal, oldVal) {
      console.log(newVal, oldVal)
      this.chanageMenues()
    }
  },
  
}
</script>

<style scoped>
.el-menu {
  border-right: none;
  margin: 0;
  padding-left: 0;
  text-align: left;
  background: transparent;
}
.topmenu {
  height: 60px;
  display: block;
  line-height: 80px;
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 70%;
  overflow: hidden;
}
.topmenu2 {
  height: 50px;
  width: 60px;
  overflow: hidden;
  font-size: 0px;
  text-indent: -1000px;
  display: block;
  padding-top: 52px;
}
.topmenu >>> .el-submenu__title{
  color: #fff;
}
.noLine {
  text-decoration: none;
}
.el-menu-item{
  color: #fff;
}
.el-menu-item.is-active {
  color: #fff;
  cursor: pointer;
  background-color: rgba(0,0,0,.15)
}
.el-menu-item:hover{
  cursor: pointer;
  background-color: rgba(0,0,0,.15)
}
.el-menu i {
  float: left;
  padding-right: 10px;
  display: inline-block;
  color: #fff;
}
</style>
